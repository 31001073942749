<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Approval Templates</v-toolbar-title>
      </v-toolbar>

      <!-- start of toolbar 2 -->
      <v-toolbar color="secondary" flat dense>
        <v-btn class="mr-1" to="/approval-templates/create" outlined color="toolbarIcon" text>
          <v-icon left dark>mdi-plus</v-icon>New
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          flat
          outlined
          solo
          dense
          label="search"
          style="width: 150px"
        ></v-text-field>
      </v-toolbar>
      <!-- end of toolbar 2 -->

      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-data-table
                :search="search"
                hide-default-footer
                :headers="headers"
                :items="approvalTemplates"
            >
              <template v-slot:item.Name="props">
                <v-btn
                  color="flatButton"
                  text
                  :to="`/approval-templates/details/${props.item.id}`"
                >{{props.item.Name}}</v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      search: null,
      approvalTemplates: [],
      dataItem: {},
      headers: [
        { text: "Name", value: "Name" },
        { text: "Description", value: "Remarks" }
        ],
    };
  },
  methods: {
    getTemplates() {
      const self = this;
      this.$store
        .dispatch("get", `/approval_templates`)
        .then((res) => {
          self.approvalTemplates = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    templateById(id) {
      const self = this;
      this.$store
        .dispatch("get", `/approval_templates/${id}`)
        .then((res) => {
          console.log(res, "one approval");
          self.dataItem = res;
          // self.createStatus = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getTemplates();
  },
};
</script>

<style lang="scss" scoped>
</style>